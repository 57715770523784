<template>
  <div class="text-truncate max-width-text">
    {{value}}
  </div>
</template>
<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number]
    },
    column: Column
  }
}
</script>

<style lang="stylus" scoped>
.max-width-text
  max-width 450px
</style>
